import React from 'react';
import imgEnglish from '../assets/img/uk.svg';
import imgSpanish from '../assets/img/spain.svg';
import styled from 'styled-components';

const Languages = ({ lang, translate }) => {
  const language =
    typeof window !== `undefined` &&
    JSON.parse(window.localStorage.getItem('translate'));

  const changeLanguage = () => {
    localStorage.setItem('buttonActive', true);
    const location = typeof window !== `undefined` && window.location;
    translate === true && location.replace('/es');
    translate === false && location.replace('/');
  };

  return (
    <>
      {lang === 'en' ? (
        <>
          <Logo src={imgSpanish} alt="spanish" />
          <span style={{ display: 'none' }}>spanish</span>
        </>
      ) : (
        <>
          <Logo src={imgEnglish} alt="english" />
          <span style={{ display: 'none' }}>english</span>
        </>
      )}
      <Button
        onClick={() => changeLanguage(!translate)}
        className="px-2 btn-sm"
      >
        {language ? (
          <>
            <p>
              <small>ES</small>
            </p>
          </>
        ) : (
          <>
            <p>
              <small>EN</small>
            </p>
          </>
        )}
      </Button>
    </>
  );
};

export default Languages;

const Logo = styled.img`
  max-width: 24px;
  max-height: 24px;
  position: fixed;
  z-index: 100;
  margin-left: 1.2em;
  margin-top: 0.5em;
`;

const Button = styled.button`
  transform: translate(20%, 10%);
  text-align: center;
  box-shadow: none !important;
  height: 35px;
  width: 40px;
  display: flex;
  align-items: flex-end;
  padding: 0;
  z-index: 200;
  position: fixed;
  p {
    color: white;
    padding-left: 2px;
    display: none;
  }
  &:hover {
    background-color: transparent;
  }
`;
