import React from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

const Pagination = ({
  itemsPerPage,
  currentPage,
  totalItems,
  paginate,
  nextPage,
  previousPage,
  lang,
}) => {
  const numberOfPages = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    numberOfPages.push(i);
  }

  return (
    <>
      {totalItems !== 0 && (
        <PaginationItems className="mt-5">
          {currentPage > 1 && (
            <button
              onClick={() => previousPage(currentPage - 1)}
              className="item-page mr-2 p-2 "
            >
              <small>
                &#171; <Trans>Previous</Trans>{' '}
              </small>
              {/* <small>&#171; {lang === 'en' ? 'Previous' : 'Anterior'}</small> */}
            </button>
          )}
          {currentPage > 1 &&
            numberOfPages.map((pagina, index) => (
              <button
                key={pagina}
                onClick={() => paginate(pagina)}
                className={`hidden mr-2 px-3 ${currentPage === index + 1 &&
                  'active'}`}
              >
                <small>{pagina}</small>
              </button>
            ))}
          {numberOfPages.length !== currentPage && (
            <button
              onClick={() => nextPage(currentPage + 1)}
              className="item-page  mr-2 p-2"
            >
              <small>
                {' '}
                <Trans>Next</Trans> &#187;
              </small>
              {/* <small> {lang === 'en' ? 'Next' : 'Siguiente'} &#187;</small> */}
            </button>
          )}
        </PaginationItems>
      )}
    </>
  );
};

export default Pagination;

const PaginationItems = styled.div`
  button {
    border: 1px solid white;
    box-shadow: none !important;
    &:hover {
      background-color: white !important;
      color: #38aeee !important;
    }
  }
  .active {
    background-color: white;
    color: #38aeee !important;
  }
  .hidden {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .item-page {
    min-width: 100px;
  }
`;
