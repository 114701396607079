import { CardBody, Col, Row } from 'reactstrap';

import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import Pagination from '../components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from './Spinner';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { useState } from 'react';

const createSlug = str => {
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();

  var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
};

function Blog(props) {
  const { title, subtitle, data } = props;
  const { edges } = data;
  const [currentPage, saveCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [loading, setLoading] = useState(false);

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const LoadSpinner = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  // Next Page
  const nextPage = valor => {
    saveCurrentPage(valor);
    LoadSpinner();
  };

  // Previos Page
  const previousPage = valor => {
    saveCurrentPage(valor);
    LoadSpinner();
  };

  const articles = edges;
  const currentPages = articles.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = paginate => {
    saveCurrentPage(paginate);
    LoadSpinner();
  };

  return (
    <>
      <Container className="cd-section">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2
              className="title"
              style={{
                fontSize: '1.4em',
                fontStyle: 'bold',
                fontWeight: 900,
              }}
            >
              {title}
            </h2>
            <h4
              className="description"
              style={{ fontSize: '15px', fontWeight: '40' }}
            >
              {subtitle}
            </h4>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Col md={{ size: 2, offset: 5 }}>
              <Spinner />
            </Col>
          ) : (
            currentPages.map(article => (
              <Col key={article.node.id} md="6" lg="4">
                <Card>
                  <BackgroundImage
                    className="bgImage"
                    fluid={
                      article.node.frontmatter.thumbnail.childImageSharp.fluid
                    }
                  >
                    <BgGradient></BgGradient>
                  </BackgroundImage>
                  <CardBody>
                    <h3>{article.node.frontmatter.title}</h3>
                    <p>{article.node.excerpt}</p>
                    <Link
                      to={`/${article.node.frontmatter.language}/${createSlug(
                        article.node.frontmatter.title
                      )}`}
                    >
                      <Trans>More</Trans>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>
        {!loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={articles.length}
            paginate={paginate}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        )}
      </Container>
    </>
  );
}

Blog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  articles: PropTypes.arrayOf({
    article: PropTypes.number,
  }),
};

export default Blog;

const Container = styled.div`
  position: relative;
  z-index: 10;
  color: white;
  padding: 0px 20px;
`;

const Card = styled.div`
  background: transparent;
  border: 0;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  margin-top: 30px;
  div > h3 {
    font-family: sans-serif;
    font-size: 1.3em;
    font-weight: 900;
  }
  div > p {
    font-size: 1.2em;
    font-weight: 30;
    margin-bottom: 1.5em;
  }
  div > a {
    display: inline;
    border: 1px solid #fff;
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    border-radius: 0;
    padding: 1em 2em;
    color: white;
    &:hover {
      opacity: 1;
    }
  }
  .bgImage {
    position: relative;
    max-width: 100%;
    min-height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const BgGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
`;

// const PageLink = styled(Link)`
//   margin-left: 0.5em;
//   margin-right: 0.5em;
//   border: 1px solid #fff;
//   box-shadow: none !important;
//   transition: all 0.2s ease-in-out;
//   border-radius: 0;
//   padding: 1em 2em;
//   color: white;
//   &:hover {
//     background-color: white;
//     color: #38aeee;
//   }
// `;
